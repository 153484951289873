<template>
  <div class="card" v-if="liquidacion.periodo">
    <div class="card-body">
      <h4 class="">
        {{ liquidacion.periodo }} {{ liquidacion.tipo_liquidacion }}
      </h4>
      <hr />
      <tabla-recibos-admin
        :tipo="2"
        :liquidacion="liquidacion"
      ></tabla-recibos-admin>
    </div>
  </div>
</template>
<script>
import Axios from "axios";
import TablaRecibosAdmin from "@/components/recibosAdmin/TablaRecibosAdmin";
export default {
  name: "VerLiquidacion",
  components: {
    TablaRecibosAdmin,
  },
  props: {
    iconoTitulo: String,
    titleCard: String,
    toCreateAction: String,
    toCreateText: String,
  },
  data() {
    return {
      liquidacion: {
        id: null,
      },
    };
  },
  mounted() {
    this.liquidacion.id = this.$route.params.id;
    this.getLiquidacion();
  },
  methods: {
    getLiquidacion() {
      Axios.get("liquidacion/" + this.liquidacion.id)
        .then((res) => {
          if (res.data != null) {
            this.liquidacion = res.data;
            this.liquidacion.recibos.sort(function (a, b) {
              if (a.nombre > b.nombre) {
                return 1;
              }
              if (a.nombre < b.nombre) {
                return -1;
              }
              // a must be equal to b
              return 0;
            });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>